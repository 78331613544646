<template>
  <div v-bind:class="colour" class="home container-fluid df">
    <section class="col loading-text" v-if="errored">
      <h2>
        Oh f*&k! We were not able to retrieve your weather data at this time,
        please try again later.
      </h2>
    </section>
    <template v-else>
      <span v-if="loading" class="col loading-text">
        <h1 class="text-center">Loading...</h1>
      </span>

      <template v-else>
        <div class="row col">
          <WeatherDisplay
            :temp="info.todayTemp"
            :weather="info.todayWeather"
            :city="city"
            :animation="animation"
          ></WeatherDisplay>
          <p class="text-center weather-component footer">
            MADE WITH LOTS OF F*&KING
            <span class="heart">&#10084;</span> BY
            <a class="link" href="https://walidsodki.com">WALID SODKI</a>
          </p>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import * as sunAnimation from "../assets/Lottie/sun.json";
import * as cloudAnimation from "../assets/Lottie/cloud.json";
import * as rainAnimation from "../assets/Lottie/rain.json";
import * as stormAnimation from "../assets/Lottie/storm.json";
import * as snowAnimation from "../assets/Lottie/snow.json";
import * as moonAnimation from "../assets/Lottie/moon.json";
import * as cloudySunAnimation from "../assets/Lottie/cloud_sun.json";
import * as cloudyMoonAnimation from "../assets/Lottie/cloud_moon.json";

import axios from "axios";

const ipAPI =
  `https://api.ipdata.co/?api-key=${process.env.VUE_APP_IP_API}`;
import WeatherDisplay from "../components/WeatherDisplay";

export default {
  name: "home",
  components: {
    WeatherDisplay
  },
  data() {
    return {
      locationLat: null,
      locationLon: null,
      loading: true,
      errored: false,
      info: {
        todayTemp: String,
        todayWeather: String,
        todaySunrise: Number,
        todaySunset: Number
      },
      city: String,
      colour: "loading",
      animation: Object,
      // list of the different animations to be passed down as prop
      sunOptions: { animationData: sunAnimation.default },
      cloudOptions: { animationData: cloudAnimation.default },
      rainOptions: { animationData: rainAnimation.default },
      stormOptions: { animationData: stormAnimation.default },
      snowOptions: { animationData: snowAnimation.default },
      moonOptions: { animationData: moonAnimation.default },
      cloudySunOptions: { animationData: cloudySunAnimation.default },
      cloudyMoonOptions: { animationData: cloudyMoonAnimation.default }
    };
  },
  mounted() {
    axios
      .get(ipAPI)
      .then(response => {
        // Instead of asking for location get it through IP lookup
        this.locationLat = response.data.latitude;
        this.locationLon = response.data.longitude;
        this.city =
          response && response.data && response.data.city
            ? response.data.city.toUpperCase()
            : "";
        // call OWM API with lat & lon data from IP lookup
        return axios.get(
          "https://api.openweathermap.org/data/2.5/weather?lat=" +
            this.locationLat +
            "&lon=" +
            this.locationLon +
            `&APPID=${process.env.VUE_APP_OPEN_WEATHER_MAP_API}&units=metric`
        );
      })
      .then(response => {
        let res = response.data;

        Object.assign(this.info, {
          // Math.round(res.main.temp)
          // res.weather[0].main.toUpperCase()
          todayTemp: Math.round(res.main.temp),
          todayWeather: res.weather[0].main.toUpperCase(),
          todaySunrise: res.sys.sunrise,
          todaySunset: res.sys.sunset
        });

        //get users current timestamp for day/night cycle
        let now = Math.floor(Date.now() / 1000);
        let night = Boolean;
        if (now - this.info.todaySunset > 0) {
          night = true;
        } else {
          night = false;
        }

        const weatherType = this.info.todayWeather.toLowerCase();
        const currentTemp = this.info.todayTemp;

        if (weatherType.includes("rain")) {
          // console.log("rainy af");
          this.colour = "mint";
          this.animation = this.rainOptions;
          Object.assign(this.info, {
            todayWeather: "POURING RAIN"
          });
        } else if (weatherType.includes("drizzle")) {
          // console.log("drizzle");
          this.colour = "mint";
          this.animation = this.rainOptions;
          Object.assign(this.info, {
            todayWeather: "DRIZZLING"
          });
        } else if (weatherType.includes("storm")) {
          // console.log("thunderstorm: ", currentTemp);
          this.colour = "fizz";
          this.animation = this.stormOptions;
          Object.assign(this.info, {
            todayWeather: "STORMING"
          });
        } else if (weatherType.includes("snow")) {
          // console.log("snow: ", currentTemp);
          this.colour = "fountain";
          this.animation = this.snowOptions;
          Object.assign(this.info, {
            todayWeather: "SNOWING"
          });
        } else if (-15 > currentTemp) {
          // console.log("cold af", currentTemp);
          this.colour = "fountain";
          this.animation = this.snowOptions;
          Object.assign(this.info, {
            todayWeather: "REALLY COLD"
          });
        } else if (weatherType.includes("cloud")) {
          // console.log("clouds", currentTemp);
          this.colour = "plum";
          if (
            res.weather[0].description.includes("few") ||
            res.weather[0].description.includes("scattered") ||
            res.weather[0].description.includes("broken")
          ) {
            Object.assign(this.info, {
              todayWeather: "CLOUDY"
            });
            if (night) {
              this.colour = "brash";
              this.animation = this.cloudyMoonOptions;
            } else {
              this.animation = this.cloudySunOptions;
            }
          } else {
            this.animation = this.cloudOptions;
            Object.assign(this.info, {
              todayWeather: "REALLY CLOUDY"
            });
          }
        } else if (weatherType.includes("clear")) {
          // console.log("clear", currentTemp);
          if (night) {
            //night
            this.animation = this.moonOptions;
            this.colour = "brash";
            Object.assign(this.info, {
              todayWeather: "CLEAR"
            });
          } else {
            //day
            this.animation = this.sunOptions;
            this.colour = "daisy";
            Object.assign(this.info, {
              todayWeather: "CLEAR"
            });
            if (currentTemp > 18) {
              // console.log("sunny af", currentTemp);
              Object.assign(this.info, {
                todayWeather: "SUNNY AF"
              });
              if (currentTemp > 29) {
                Object.assign(this.info, {
                  todayWeather: "REALLY WARM"
                });
              }
            }
          }
        } else {
          this.animation = this.cloudOptions;
          this.colour = "plum";
          Object.assign(this.info, {
            todayWeather: this.info.todayWeather.toUpperCase() + "ING"
          });
        }
      })
      .catch((error) => {
        this.errored = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>
