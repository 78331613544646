<template>
  <div class="container-fluid text-center">
    <div class="weather-component">
      <div class="row">
        <div class="weather-display col">
          <h1 class="temp-text">{{ temp }}°</h1>
          <h3 class="weather-text">IT'S F*&KING {{ weather }}</h3>
          <h5 class="city-text">{{ city }}</h5>
        </div>
      </div>
      <div class="row">
        <div>
          <lottie
            class="img-fluid character-image"
            :options="animation"
            v-on:animCreated="handleAnimation"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from "vue-lottie";

export default {
  name: "WeatherDisplay",
  components: {
    lottie
  },
  data() {
    return {
      animationSpeed: 1
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    }
  },
  props: {
    temp: Number,
    weather: String,
    city: String,
    img: String,
    animation: Object
  }
};
</script>
